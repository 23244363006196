<template>
  <p-click-outside :do="() => calendarVisible = false">    
    <div class="relative">  

      <PLabel 
        :label="label" 
        :error="error" 
        :required="required"
        @click="$refs.input.focus()" 
      />

      <button 
        ref="button"
        @click.prevent="toggle" 
        @keydown.esc.stop.prevent="esc"
        @keydown.delete="reset"
        :class="toggleButtonClassNames"
        :disabled="disabled"
        class="flex items-center p-2 h-10 text-sm border shadow-inner leading-none w-full focus:outline-none"
      >
                
        <font-awesome-icon 
          :class="{ 
            'text-gray-300': disabled, 
            'text-gray-400': !disabled && !error,
            'text-red-400': error }"
          :icon="['far', 'calendar']" 
        />

        <div class="ml-2 flex-1 text-left whitespace-no-wrap">
          <template v-if="selectedDate">
            {{ selectedDate }}
          </template>
          <template v-else-if="placeholder">
            <div class="text-gray-400" v-html="placeholder"></div>
          </template>                    
        </div>
        
          <FontAwesomeIcon 
            class="mr-1"
            @click.prevent.stop="reset"
            v-if="selectedDate && clearable"
            :class="{ 
              'text-gray-300': disabled, 
              'text-gray-600': !disabled && !error,
              'text-red-400': error }"
            :icon="['far', 'times']" 
          />

      </button>
    
      <div 
        v-show="calendarVisible" 
        style="margin-top: -1px"
        class="absolute flex p-2 flex-wrap bg-white z-20 shadow-md w-64 border border-gray-500 rounded-b"
        :class="{ 
          'left-0': !rightAlign,
          'right-0': rightAlign
        }"
      >
        <div class="flex items-center w-full bg-white border-gray-500 text-center">
          <button 
            @click.prevent="prev" 
            :disabled="loading"
            class="flex-0 pr-2 text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none w-10 border-gray-500"
          >
            <FontAwesomeIcon :icon="['far', 'chevron-left']" />
          </button>

          <div class="flex-1 p-2 font-semibold text-gray-700" v-html="selectedYear"></div>

          <button 
            @click.prevent="next" 
            :disabled="loading"
            class="flex-0 pl-2 text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none w-10 border-gray-500"
          >
            <FontAwesomeIcon :icon="['far', 'chevron-right']" />
          </button>

        </div>
        
        <template class="flex flex-wrap">

          <button 
            v-for="(month, index) in months" 
            :key="index" 
            :disabled="!allowedDate(month.value)"
            class="w-1/3 h-8 w-8 text-sm focus:outline-none leading-none"
            :class="{ 
              'bg-green-500 text-white': month.value === selectedMonth,
              'hover:bg-green-300 hover:text-white focus:bg-green-300 focus:text-white' : allowedDate(month.value),
              'text-gray-300 bg-white hover:text-gray-300 hover:bg-white focus:bg-white': !allowedDate(month.value)
            }"                            
            @click.prevent="select(month)"
          >

            {{ month.name }}

          </button>

        </template>

      </div>

    </div>
  </p-click-outside>
</template>

<script>
import { format, lastDayOfMonth } from "date-fns"
import PLabel from "./partials/PLabel"

export default {
  name: "p-month-picker",
  components: {
    PLabel
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    maxValue: {
      type: String,
      default: undefined
    },
    emitFirstDayOfMonth: {
      type: Boolean,
      default: false
    },
    emitLastDayOfMonth: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    rightAlign: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ""
    },
    focus: {
      type: Boolean,
      default: false
    },
    allOpen: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { 
      selectedDate: "",
      selectedYear: this.value.split('-')[0],
      selectedMonth: this.value.split('-')[1],
      months: [
        { value: "01", name: this.$tk("Common.Months.January")},
        { value: "02", name: this.$tk("Common.Months.February")},
        { value: "03", name: this.$tk("Common.Months.March")},
        { value: "04", name: this.$tk("Common.Months.April")},
        { value: "05", name: this.$tk("Common.Months.May")},
        { value: "06", name: this.$tk("Common.Months.June")},
        { value: "07", name: this.$tk("Common.Months.July")},
        { value: "08", name: this.$tk("Common.Months.August")},
        { value: "09", name: this.$tk("Common.Months.September")},
        { value: "10", name: this.$tk("Common.Months.October")},
        { value: "11", name: this.$tk("Common.Months.November")},
        { value: "12", name: this.$tk("Common.Months.December")}
      ],
      currentDate: new Date(),
      calendarVisible: false,
      loading: false
    }
  },
  computed: {
    selectedYearMonth () {
      return this.selectedYear + '-' + this.selectedMonth
    },
    selectedYearMonthWithFirstDayOfMonth () {
      return this.selectedYearMonth + '-01'
    },
    selectedYearMonthWithLastDayOfMonth () {
      return  this.selectedYearMonth + "-" + format(lastDayOfMonth(new Date(this.selectedYear, parseInt(this.selectedMonth) - 1, "01")), "d")
    },
    toggleButtonClassNames () {
      return {
        "bg-white border-gray-400 focus:border-white focus:shadow-outline": !this.error && !this.disabled,
        "bg-gray-50 border-gray-300 text-gray-400 cursor-not-allowed": this.disabled,
        "border-red-500 focus:border-red-600 placeholder-red-300 text-red-600": this.error,
        "rounded": !this.calendarVisible, 
        "rounded-t": this.calendarVisible, 
        "border-gray-500": this.calendarVisible
      }
    },
    firstDayOfMonth () {
      if (this.calendarYearMonth) {
        return new Date(`${this.calendarYearMonth}-01`).getDay()        
      }
      return 0
    },
    calendarYearDisplay () {
      return this.calendarYear
    }
  },
  filters: {
    day (date) {
      const parts = date.split("-")
      return parseInt(parts[parts.length - 1])
    }
  },
  methods: {
    allowedDate (month) {
      if (this.maxValue === undefined) {
        return true
      }
      let t = this.maxValue.split('-')
      let maxYear = parseInt(t[0])
      let maxMonth = parseInt(t[1])
      if (this.selectedYear >= maxYear) {
        if (parseInt(month) > maxMonth) {
          return false
        }
      }
      return true
    },
    toggle () {
      this.calendarVisible = !this.calendarVisible
    },
    reset () {
      this.$emit("input", "")
      this.selectedDate = ""
      this.calendarVisible = false
    },
    select (month) {
      this.selectedMonth = month.value
      this.selectedDate = this.selectedYearMonth

      if (this.emitFirstDayOfMonth) {
        this.$emit("input", this.selectedYearMonthWithFirstDayOfMonth)
      } else if (this.emitLastDayOfMonth) {
        this.$emit("input", this.selectedYearMonthWithLastDayOfMonth)
      } else {
        this.$emit("input", this.selectedYearMonth)
      }
      this.$refs.button.focus()
      this.calendarVisible = false
    },
    esc () {
      this.calendarVisible = false
    },
    prev () {
      this.selectedMonth = ""
      this.selectedYear--
    },
    next () {
      this.selectedMonth = ""
      this.selectedYear++
    },
    pad(number) {
      return number < 10 ? '0' + number : '' + number
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs.button.focus()
    }
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) {
          let year = val.split('-')[0]
          let month = val.split('-')[1]
          this.selectedYear = parseInt(year)
          this.selectedMonth = month
          this.selectedDate = year + '-' +  month
        }
      }, 
      immediate: true
    }
  }
}
</script>